// BASIC
export const PRIMARY = '#898aa1';
export const SECONDARY = '#767676';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

// ACTIONS
export const SUCCESS = '#28A745';
export const INFO = '##cff4fc';
export const WARNING = '#ffae00';
export const ALERT = '#cc4b37';
export const DEFAULT = '#E0E0E0';

// GRAYSCALE
export const GRAY_LIGHT = '#e6e6e6';
export const GRAY_MEDIUM = '#cacaca';
export const GRAY_DARK = '#8a8a8a';

// BRANDS
export const BRAND_FACEBOOK = '#3b5998';
export const BRAND_TWITTER = '#1da1f2';
export const BRAND_INSTAGRAM = '#c13584';

// THEME
// Utilizar los mismos valores que están en styles/w3-theme.css
export const THEME_L5 = '#f8f8f9';
export const THEME_L4 = '#e7e7ec';
export const THEME_L3 = '#cfd0d9';
export const THEME_L2 = '#b8b8c6';
export const THEME_L1 = '#a0a1b3';
export const THEME = '#898aa1';
export const THEME_D1 = '#787993';
export const THEME_D2 = '#696a83';
export const THEME_D3 = '#5c5d73';
export const THEME_D4 = '#4f5063';
export const THEME_D5 = '#424252';